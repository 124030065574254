import * as React from "react";
import { ArrowRight } from "../images/icons";

const NextBtn = ({ btnClass, iconClass }) => {
  return (
    <div
      className={`${btnClass} relative flex w-[30px] h-[30px] justify-center content-center items-center`}
    >
      <div className="bg-white opacity-20 w-full h-full rounded-full absolute left-0 top-0"></div>
      <ArrowRight
        classes={`rotate text-white absolute  w-[17.5px] top-[51%] translate-y-[-50%] translate-x-[-50%] left-[52.5%] ${iconClass}`}
      />
    </div>
  );
};
export default NextBtn;
