import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { TimelineIcon } from "../images/icons";

const CarruselChildSign = ({ data, index }) => {
  const image = getImage(
    data.heroImage.localFile.childImageSharp.gatsbyImageData
  );
  return (
    <Link
      to={`/signs/${data.slug}`}
      className={` items-center flex-col h-max p-0 overflow-hidden cursor-pointer opacity-100 transition ease-in-out duration-300`}
    >
      <div className="group transition ease-in-out flex items-center justify-items-center flex-col h-max p-0 overflow-hidden cursor-pointer">
        <div className="mb-[0.65em] grid-container place-items-center">
          <div className="item-grid-content">
            {data.heroImage && (
              <GatsbyImage
                image={image}
                alt=""
                className="opacity-100 group-hover:opacity-10 duration-300"
                imgStyle=""
              />
            )}
          </div>
          {/* Caption on hover */}
          <div className="flex flex-col justify-center opacity-0 text-center scale-95 group-hover:scale-100 group-hover:opacity-100 duration-300 text-white w-full h-full z-10 item-grid-overlay">
            <div className=" capitalize">{data.title}</div>
          </div>
        </div>
        <div className="mt-[0.65em] opacity-100   group-hover:opacity-0 duration-300 ">
          <div className="w-full flex justify-center translate-y-0 group-hover:translate-y-5 duration-300">
            <TimelineIcon w={"25"} />
          </div>
          <div className="text-white text-base  mt-[1.3em]">{data.year}</div>
        </div>
      </div>
    </Link>
  );
};

export default CarruselChildSign;
