// This is the template for a single sign
import * as React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout";
import CarruselChildSign from "../../../components/carruselChildSign";
import CarruselChildEvent from "../../../components/carruselChildEvent";
import CarouselMulti from "../../../components/carruselMulti";
import CarouselMultiFade from "../../../components/carruselMultiFade";
import { EyeOutline, ArrowRight } from "../../../images/icons";
import ModalImg from "../../../components/modalImg";
import { Seo } from "../../../components/seo";
import { undelineSM } from "../../../utils/cssGroups";

// ------------> EVENTS (event) page <------------ //
const Page = (props) => {
  // const event = props.data.strapiEvent;
  const {
    eventTitle,
    eventType,
    eventDate,
    addReactions,
    eventDescription,
    eventLocation,
    eventSigns,
    eventImages,
    comingSoon,
  } = props.data.strapiEvent;
  // ---> get Number of signs in an event to pass as state to the signs carousel <--- //
  const [allSigns, setAllSigns] = React.useState(null);
  // ---> Set the number of signs in 1st render <--- //
  React.useEffect(() => {
    setAllSigns(eventSigns.length);
  }, [eventSigns.length]);
  // Date:
  const date = new Date(eventDate);
  const dateFormated = date.toDateString().split(" ").slice(1).join(" ");
  // ---> Modal Setup <--- //
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const handleOnClose = () => setShowModal(false);

  // keypress
  function handleKeyDown(e, reactionImage) {
    if (e.keyCode === 13) {
      setShowModal(true);
      setModalContent(reactionImage);
    }
  }

  return (
    <Layout
      location={props.location}
      bodyClass={"bg-amber-300"}
      bodyOverflow={showModal ? "overflow-hidden" : "overflow-auto"}
    >
      <div className="text-black">
        {/* Title */}
        <h1
          className={`capitalize text-lg md:text-xl  mb-[2.6rem] md:mb-[3.9rem]`}
        >
          {eventTitle}
        </h1>
        {/* ---> Event Images Carrusel */}
        {/* <div>
          <Carrusel dataImages={eventImages} classes={"mb-[2.6rem] "} />
        </div> */}
        <div className="mb-[2.6rem]">
          <CarouselMultiFade
            centred={"justify-center"}
            show={1}
            bgColor={""}
            paddingSize={""}
            childComponent={true}
            // state of total number of signs for carrusel after load
            signsLength={eventImages.length}
          >
            <>
              {/*  Map all event images data */}
              {eventImages.map((a, i) => {
                return <CarruselChildEvent key={i} data={a} />;
              })}
            </>
          </CarouselMultiFade>
        </div>
        {/* ---> Type */}

        <div className={"mb-[1.3em]"}>
          <div className={""}>Event Type:</div>
          <div className="md:text-md">{eventType}</div>
        </div>

        {/* ---> Description */}
        <div className={"mb-[1.3em]"}>
          <div className={""}>Details:</div>
          <div className="md:text-md">{eventDescription}</div>
        </div>

        {/* ---> Location */}
        <div className={"mb-[1.3em]"}>
          <div className={""}> Location:</div>
          <div className="md:text-md capitalize">
            {eventLocation.country}, {eventLocation.city}
          </div>
        </div>

        {/* Date */}
        <div className={"mb-[1.3em]"}>
          <div className={""}>Date:</div>
          <div className="md:text-md">{dateFormated}</div>
        </div>

        {/* ---> Signs Carrusel  */}
        {/* <div className="flex flex-wrap items-center  mb-[0.65em]"> */}
        <EyeOutline classes={"mb-[0.65rem] basis-full h-3 md:h-4 mr-5 "} />

        <div className=" mb-[1.3em]">Signs Created:</div>
        {/* </div> */}
        <CarouselMulti
          show={3}
          bgColor={"bg-black"}
          paddingSize={"py-10"}
          childComponent={true}
          // state of total number of signs for carrusel after load
          signsLength={allSigns}
        >
          <>
            {/*  Map all signs data */}
            {eventSigns.map((a, i) => {
              // Pass single sign data to child component (a sign card)
              return <CarruselChildSign key={i} data={a} />;
            })}
          </>
        </CarouselMulti>

        {/* ---> Reactions Section */}
        <div className="mt-[2.6em] mb-[1.3em] ">Reactions:</div>
        {/* modal for reaction Image */}
        <ModalImg
          modalData={modalContent}
          visible={showModal}
          onClose={handleOnClose}
        />
        <CarouselMulti
          show={3}
          bgColor={"transparent"}
          paddingSize={"p-0"}
          addClass={"mx-[-0.65rem]"}
          childComponent={false}
        >
          {addReactions.map((reaction, index) => {
            const reactionImage = getImage(
              reaction.participantImage !== null &&
                reaction.participantImage.localFile.childImageSharp
                  .gatsbyImageData
            );
            return (
              <div key={index} className="inline-block italic text-amber-300 ">
                <div className={`pr-0 md:pr-10 h-full mx-[0.65rem]`}>
                  <div className={` text-black text-md`}>""</div>
                  {/* Image or Quate */}
                  {reaction.participantQuote !== null ? (
                    <div className="text-amber-300 mt-0 relative block max-w-full leading-[2.2rem] mb-[1.3em]">
                      <span className="inline bg-black italic box-decoration-clone px-2 group-hover:bg-white duration-300">
                        {reaction.participantQuote}
                      </span>
                    </div>
                  ) : (
                    <div
                      className=" bg-black flex justify-center justify-items-center mb-[1.3em] py-4 "
                      onClick={() => {
                        setShowModal(true);
                        setModalContent(reactionImage);
                      }}
                      role={"button"}
                      tabIndex={0}
                      onKeyDown={handleKeyDown(reactionImage)}
                    >
                      {/* Open modal for Image */}
                      <div className="w-1/2 md:w-3/4 xl:w-1/2">
                        <GatsbyImage
                          image={reactionImage}
                          alt={"image for " + reaction.participantName}
                        />
                      </div>
                    </div>
                  )}
                  <div className={` text-black text-sm capitalize`}>
                    — {reaction.participantName}
                  </div>
                </div>
              </div>
            );
          })}
        </CarouselMulti>
        <Link
          className={
            "mt-[2.6em] flex items-center capitalize group transition-colors"
          }
          to={`/events/`}
        >
          <ArrowRight
            classes={
              "h-3.5 lg:h-4 pt-[0.2em] group-hover:text-white duration-300 mr-3"
            }
          />
          <div className={`group-hover:text-white  duration-300 ${undelineSM}`}>
            Explore more events...
          </div>
        </Link>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    strapiEvent(slug: { eq: $slug }) {
      eventLocation {
        country
        city
      }
      eventSigns {
        slug
        year
        title
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: NO_CHANGE
                #width: 700
                height: 400
                quality: 100
                #layout: FULL_WIDTH
                transformOptions: { fit: COVER }
                layout: CONSTRAINED
              )
            }
          }
        }
      }
      eventFeatured
      eventDescription
      eventTitle
      eventType
      eventDate
      slug
      eventImages {
        caption
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: NO_CHANGE
              height: 600
              quality: 100
              transformOptions: { fit: CONTAIN, cropFocus: ATTENTION }
              layout: CONSTRAINED
            )
          }
        }
      }
      addReactions {
        participantImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: NO_CHANGE
                #width: 1200
                height: 800
                quality: 100
                #transformOptions: { fit: CONTAIN, cropFocus: ATTENTION }
                layout: CONSTRAINED
              )
            }
          }
        }
        participantName
        participantQuote
      }
    }
  }
`;

export default Page;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Event"}
    description={
      "Exhibitions exploring public prespectives around The Impact Of Extreme Climate Futures Through Signage Systems"
    }
    keywords={
      "specualtive design, workshops, art instalation, popup exhibition"
    }
  />
);
