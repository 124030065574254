import * as React from "react";
import useKeyPress from "../utils/useKeyPressed";
import { CloseIcon } from "../images/icons";
import { GatsbyImage } from "gatsby-plugin-image";
import { getWindowSize } from "../utils/getWindowSize";

export default function ModalImg({ visible, onClose, modalData }) {
  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      onClose();
    }
  }
  const pressed = useKeyPress("Escape");

  pressed && onClose();

  const [windowSize, setWindowSize] = React.useState(
    getWindowSize().innerHeight
  );

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize().innerHeight);
    }

    typeof window !== "undefined" &&
      window.addEventListener("resize", handleWindowResize);
    return () => {
      typeof window !== "undefined" &&
        window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  if (!visible) return null;
  return (
    // ---> Modal <--- //
    <div
      // close when opacity backriund is clicked
      onClick={onClose}
      role={"button"}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      className={`pointer-events${
        visible ? "-true animate-pulse" : "-none"
      }  flex w-screen h-screen fixed top-0 left-0 z-50 inset-0 bg-black bg-opacity-50 transition-opacity duration-300 backdrop-blur-sm justify-center items-center ${
        visible ? "opacity-100" : "opacity-0"
      } `}
    >
      {/* ---> Close Button <--- */}
      <div className="fixed right-[1rem] top-[1rem] sm:right-[2rem] sm:top-[2rem] md:right-[2rem] md:top-[2rem] lg:right-[3rem] lg:top-[3rem]">
        <div className="flex justify-end">
          <button onClick={onClose}>
            <CloseIcon classes={"text-white"} w={20} />
          </button>
        </div>
      </div>
      {/* ---> Modal Image <---  */}
      <div
        className=" overflow-y-auto hideScroll flex justify-center p-5"
        // style={{ height: windowSize, width: "auto" }}
      >
        {/* ---> Modal Content <---  */}
        <div className="">
          <GatsbyImage image={modalData} alt={"image for"} />
        </div>
      </div>
    </div>
  );
}
