import React, { useEffect, useState } from "react";
// import { CarruselArrowLeft, CarruselArrowRight } from "../images/icons";
// import { getWindowSize } from "../utils/getWindowSize";
import PrevBtn from "./prevBtn";
import NextBtn from "./nextBtn";

const CarouselMultiFade = ({
  children,
  show,
  bgColor,
  paddingSize,
  signsLength,
  childComponent,
  addClass,
  key,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children.length);
  const [touchPosition, setTouchPosition] = useState(null);
  // Fade on slide
  const [itemFade, setItemFade] = useState(true);
  // Adjust number of items based on window size
  // const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    // Set the length of carrusel to match current children from props
    setLength(childComponent ? signsLength : children.length);
    // --- Handle Window Size:
    // function handleWindowResize() {
    //   setWindowSize(getWindowSize());
    // }
    // window.addEventListener("resize", handleWindowResize);
    // return () => {
    //   window.removeEventListener("resize", handleWindowResize);
    // };
  }, [signsLength, childComponent, children.length]);

  const next = () => {
    if (currentIndex < length - show) {
      setCurrentIndex((prevState) => prevState + 1);
      setItemFade(true);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
      setItemFade(true);
    }
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  // Chnage SHOW based on Window Size:
  // var carouselCountDisplay;
  // if (windowSize.innerWidth <= 1024 && windowSize.innerWidth > 768) {
  //   show = 2;
  //   carouselCountDisplay = `show-${show}`;
  // } else if (windowSize.innerWidth <= 768 && windowSize.innerWidth > 0) {
  //   show = 1;
  //   carouselCountDisplay = `show-${show}`;
  // } else {
  //   carouselCountDisplay = `show-${show}`;
  // }

  // Fade
  useEffect(() => {
    setTimeout(function () {
      setItemFade(false);
    }, 250);
  }, [currentIndex]);

  return (
    <div className={`flex w-full relative ${bgColor} ${paddingSize} `}>
      <button
        onClick={prev}
        className={`absolute z-20 top-2/4 translate-y-[-50%] w-[20px] h-[20px] left-[24px]  ${
          currentIndex > 0 ? "pointer-events-auto " : "pointer-events-none "
        }`}
      >
        <PrevBtn btnClass={currentIndex > 0 ? "opacity-100" : "opacity-20"} />
      </button>
      <div
        className={`overflow-hidden w-full h-full `}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {/* transition-all */}
        <div
          className={`flex carousel-multi-hide-scrollbar-x show-${show} ${addClass}
          ${itemFade ? "animate-pulse" : ""}
          `}
          style={{
            transform: `translateX(-${currentIndex * (100 / show)}%)`,
          }}
        >
          {childComponent
            ? React.cloneElement(children, {
                data: null,
              })
            : children}
        </div>
      </div>
      <button
        onClick={next}
        className={`absolute z-20 top-2/4 translate-y-[-50%] w-[20px] h-[20px] right-[24px]  ${
          currentIndex < length - show
            ? "pointer-events-auto "
            : "pointer-events-none "
        } `}
      >
        <NextBtn
          btnClass={currentIndex < length - show ? "opacity-100" : "opacity-20"}
        />
      </button>
    </div>
  );
};

export default CarouselMultiFade;
