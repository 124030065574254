import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CarruselChildEvent = ({ data, index, currentIndex }) => {
  const image = getImage(data.localFile.childImageSharp.gatsbyImageData);

  return (
    <div className="flex justify-center  group transition ease-in-out p-0 overflow-hidden">
      {data && <GatsbyImage image={image} alt="" className="" imgStyle="" />}
    </div>
  );
};

export default CarruselChildEvent;
